@font-face {
    font-family: "Work Sans";
    src: url(../../../asset/font/WorkSans-Bold.ttf);
    font-weight: 700;
}

@font-face {
    font-family: "Work Sans";
    src: url(../../../asset/font/WorkSans-SemiBold.ttf);
    font-weight: 600;
}

@font-face {
    font-family: "Work Sans";
    src: url(../../../asset/font/WorkSans-Medium.ttf);
    font-weight: 500;
}

@font-face {
    font-family: "Work Sans";
    src: url(../../../asset/font/WorkSans-Regular.ttf);
    font-weight: 400;
}
